import { Component, OnDestroy, OnInit } from '@angular/core';
import { APPS } from '@config';
import { UserContextService, UserService } from '@features/auth';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConstructionWithAvailableConfig } from './shared/generated';
import { PacwebState } from './store';
import { CranesActions, CranesSelectors } from './store/cranes';

@Component({
  selector: 'pw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnDestroy, OnInit {
  calculation$: Observable<ConstructionWithAvailableConfig | null>;
  helpDocumentUrl$: Observable<string | null>;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store<PacwebState>,
    private userContextService: UserContextService,
    private userService: UserService,
  ) {
    this.calculation$ = this.store.select(CranesSelectors.calculation);
    this.helpDocumentUrl$ = this.store.select(CranesSelectors.helpDocumentUrl);
  }

  ngOnInit() {
    this.userContextService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe((userContext) => {
        if (userContext) {
          /**
           * set url for help document according to user language with "EN" as fallback
           */
          let helpDocumentUrl =
            'https://pacweb.blob.core.windows.net/documents/PACWEB_Help_Information_new_';
          switch (userContext.lang.toUpperCase()) {
            case 'DE': {
              helpDocumentUrl += 'DE.pdf';
              break;
            }
            case 'ES': {
              helpDocumentUrl += 'ES.pdf';
              break;
            }
            case 'FR': {
              helpDocumentUrl += 'FR.pdf';
              break;
            }
            default: {
              helpDocumentUrl += 'EN.pdf';
              break;
            }
          }
          this.store.dispatch(
            CranesActions.SetHelpUrl({ payload: helpDocumentUrl }),
          );
          /**
           * set username for display in header
           */
          this.store.dispatch(
            CranesActions.SetUserName({
              payload: userContext.username,
            }),
          );
          /**
           * initial load of all available calculation infos
           */
          this.store.dispatch(
            CranesActions.LoadCalculationInfos({
              payload: { searchTerm: '', pageIndex: 0, pageSize: 20 },
            }),
          );
          /**
           * initial load of truck manufacturers
           */
          this.store.dispatch(CranesActions.LoadTruckManufacturers());
          /**
           * initial load of categories
           */
          this.store.dispatch(CranesActions.LoadCategories());
          /**
           * initial load of epsilon categories
           */
          this.store.dispatch(CranesActions.LoadEpsilonCategories());
          /**
           * initial load of stabilizers
           */
          this.store.dispatch(CranesActions.LoadStabilizers());
          /**
           * check if user has delete and poweruser rights
           */
          this.store.dispatch(
            CranesActions.SetPowerUser({
              payload: this.userService.hasRole(APPS.PACWEB.ROLES.POWERUSER),
            }),
          );
          this.store.dispatch(
            CranesActions.SetDeleteUser({
              payload:
                this.userService.hasRole(APPS.PACWEB.ROLES.DELETE) &&
                this.userService.isPartnerType(1), // only AG user with delete role can delete
            }),
          );
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
